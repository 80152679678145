exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-jsx": () => import("./../../../src/pages/calculator.jsx" /* webpackChunkName: "component---src-pages-calculator-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-slug-js": () => import("./../../../src/templates/blog/[...slug].js" /* webpackChunkName: "component---src-templates-blog-slug-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-templates-quiz-slug-js": () => import("./../../../src/templates/quiz/[...slug].js" /* webpackChunkName: "component---src-templates-quiz-slug-js" */)
}

